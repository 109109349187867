@import '../../utils/sass/variables.scss';

.mainContainer {
  margin: 0 auto;
}

.contentContainer {
  max-width: 1920px;
  margin: 0 auto;
}
