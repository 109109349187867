@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  h1 {
    @apply text-3xl font-bold;
  }
  h2 {
    @apply text-2xl font-bold;
  }
  h3 {
    @apply text-xl font-bold;
  }
  h4 {
    @apply text-lg font-bold;
  }
  h5 {
    @apply text-base;
  }
  a {
    @apply text-blue-600 underline;
  }
  p {
    @apply font-light;
  }
}

@layer utilities {
  .link {
    @apply text-[#0C21C1] no-underline hover:underline font-medium;
  }

  .inputField {
    @apply border-b-[1px] border-b-primary-grey font-medium text-[#000842] outline-0 pr-2 py-1 placeholder-[#000842]
		transition-all focus:border-b-primary;
  }

  .primaryButton {
    @apply py-2 px-4 hover:bg-primary-hover transition-all bg-primary text-white;
  }
}

body {
  font-family: 'Poppins', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  background: rgb(197, 196, 213);
  background: linear-gradient(90deg, rgba(197, 196, 213, 1) 0%, rgba(207, 207, 233, 1) 35%, rgba(0, 212, 255, 1) 100%);
}
